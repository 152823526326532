import React from 'react';
import UrlProcessor from './urlProcessor';
import App from './App';

const AppWrapper = ({ location, match }) => {
  return (
    <UrlProcessor location={location} match={match}>
      <App />
    </UrlProcessor>
  );
};

export default AppWrapper;
