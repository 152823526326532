import React from 'react';
import { connect } from 'react-redux';
import { isEmpty, equals, isNil } from 'ramda';
import queryString from 'query-string';
import { fixParams, validateParams, hasUnrecoverableError } from '../utils/urlProcessorHelper';
import * as urlParamsActions from '../redux/modules/urlParams';
import * as tableDataActions from '../redux/modules/tableData';
import * as clientsActions from '../redux/modules/clients';
import * as pickersActions from '../redux/modules/pickers';
import { DIMENSIONS } from '../constants/main';
import Loader from './Loader';
import BadUrlPage from './BadUrlPage';

function UrlProcessor({
  children,
  clients,
  dataFetched,
  errorMessage,
  getData,
  getOverviewData,
  gettingData,
  match,
  updateNetwork,
  updatePickers,
  updatePeriod,
  updateSelectedClient,
  updateUrlParams,
  urlConfig,
  location,
  updateCurrentTable,
  updateFilterList,
}) {
  if (isEmpty(clients)) {
    return null;
  }

  if (Object.values(match.params).every(param => !isNil(param))) {
    const errors = validateParams({ ...match.params, clients });
    if (hasUnrecoverableError(errors)) {
      return <BadUrlPage />;
    }
    let searchParams = queryString.parse(location.search);
    if (typeof searchParams.filter === 'string') {
      searchParams.filter = [searchParams.filter];
    }

    const params = fixParams({ ...match.params, range: 0 }, errors);
    const clientId = match.params.client;
    const newUrlConfig = {
      clientId,
      dimensions: DIMENSIONS,
      others: false,
      mainDateFrom: params.from,
      mainDateTo: params.to,
      compareDateFrom: params.compareFrom,
      compareDateTo: params.compareTo,
      period: params.period,
      filters: searchParams.filter || 'total',
      network: params.network,
      breakdown: searchParams.breakdown || 'all',
    };

    urlConfig.network = params.network;

    if (!equals(urlConfig, newUrlConfig)) {
      updateUrlParams({ ...newUrlConfig }, { procedWithUpdate: true });
      updateSelectedClient(clients.filter(c => c.client_unique_id === clientId)[0]);
      updateNetwork(params.network);
      updatePeriod(params.period);
      updatePickers(params.range, params.from, params.to, params.compareFrom, params.compareTo);
      updateCurrentTable(searchParams.breakdown);
      updateFilterList(searchParams.filter);
      dataFetched = false;
    }

    if (!dataFetched && !errorMessage) {
      if (newUrlConfig.network === 'overview') {
        delete newUrlConfig.network;
        getOverviewData({ ...newUrlConfig, filter: ['total'] });
      } else {
        getData(newUrlConfig);
      }

      return <Loader />;
    }

    return children;
  }

  return children;
}

const mapStateToProps = state => ({
  clients: state.clients.clients,
  urlConfig: state.urlParams,
  dataFetched: state.tableData.dataFetched,
  errorMessage: state.tableData.errorMessage,
  gettingData: state.tableData.gettingData,
  filters: state.clients.selectedFilters,
  breakdown: state.tableData.currentTable,
});

const mapDispatchToProps = {
  updateUrlParams: urlParamsActions.updateUrlParams,
  updateNetwork: clientsActions.updateNetwork,
  updateSelectedClient: clientsActions.updateSelectedClient,
  updateFilterList: clientsActions.updateFilterList,
  updatePickers: pickersActions.updatePickers,
  updatePeriod: pickersActions.updatePeriod,
  getData: tableDataActions.getData,
  getOverviewData: tableDataActions.getOverviewData,
  updateCurrentTable: tableDataActions.updateCurrentTable,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UrlProcessor);
