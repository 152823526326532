import React, { Suspense } from 'react';
import { connect } from 'react-redux';
import * as urlParamsActions from '../redux/modules/urlParams';
import * as clientsActions from '../redux/modules/clients';
import Loader from './Loader';

const Main = React.lazy(() => import('./Main'));

const App = ({ network, userLoggedIn, updateSelectedClient, updateUrlParams, client }) => {
  return (
    <React.Fragment>
      <Suspense fallback={<Loader />}>
        <Main
          network={network}
          userLoggedIn={userLoggedIn}
          updateUrlParams={updateUrlParams}
          updateSelectedClient={updateSelectedClient}
          client={client}
        />
      </Suspense>
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  network: state.clients.network,
  userLoggedIn: state.clients.userLoggedIn,
  client: state.clients.clients[0],
});

const mapDispatchToProps = {
  updateUrlParams: urlParamsActions.updateUrlParams,
  updateSelectedClient: clientsActions.updateSelectedClient,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
