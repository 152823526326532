import { DIMENSIONS } from '../../constants/main';
const actionPrefix = 'peribianreporting/urlParams/';

export const UPDATE_URL_PARAMS = `${actionPrefix}UPDATE_URL_PARAMS`;
export const UPDATE_FILTERS = `${actionPrefix}UPDATE_FILTERS`;

const initialState = {
  clientId: '',
  dimensions: DIMENSIONS,
  others: false,
  mainDateFrom: '',
  mainDateTo: '',
  compareDateFrom: '',
  compareDateTo: '',
  period: '',
  filters: [],
  network: '',
  breakdown: 'all',
};

export const updateUrlParams = (urlConfig, meta) => dispatch =>
  dispatch({
    type: UPDATE_URL_PARAMS,
    urlConfig,
    meta,
  });

export const updateFilters = filters => dispatch =>
  dispatch({
    type: UPDATE_FILTERS,
    filters,
  });

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_URL_PARAMS:
      return {
        ...state,
        ...action.urlConfig,
      };
    case UPDATE_FILTERS:
      return {
        ...state,
        filters: action.filters,
      };
    default:
      return state;
  }
};

export default reducer;
