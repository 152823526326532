import queryString from 'query-string';
import { UPDATE_URL_PARAMS } from '../modules/urlParams';

export const updateUrl = store => next => action => {
  if (action.type !== UPDATE_URL_PARAMS) {
    next(action);
    return;
  }

  const urlConfig = { ...store.getState().urlParams, ...action.urlConfig };
  window.location.hash = `#/${urlConfig.clientId}/${urlConfig.network}/${urlConfig.period}/${
    urlConfig.mainDateFrom
  }/${urlConfig.mainDateTo}/${urlConfig.compareDateFrom}/${
    urlConfig.compareDateTo
  }?${queryString.stringify({ filter: urlConfig.filters, breakdown: urlConfig.breakdown })}`;

  if (action.meta && action.meta.procedWithUpdate) {
    next(action);
    return;
  }

  return;
};
