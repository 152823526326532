import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { loginAdmin } from '../../redux/modules/auth';
import SignIn from '../SignIn';

const Login = ({ location, userLoggedIn }) => {
  const { from } = location.state || { from: { pathname: '/' } };
  if (userLoggedIn) {
    return <Redirect to={from} />;
  }

  return (
    <React.Fragment>
      <SignIn onClick={loginAdmin} />
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  userLoggedIn: state.auth.userLoggedIn,
});

export default connect(mapStateToProps)(Login);
