import _isEmpty from 'lodash/isEmpty';
import api from '../../utils/api';

const actionPrefix = 'peribianreporting/tableData/';

export const GET_DATA_START = `${actionPrefix}GET_DATA_START`;
export const GET_DATA_SUCCESS = `${actionPrefix}GET_DATA_SUCCESS`;
export const GET_DATA_FAILURE = `${actionPrefix}GET_DATA_FAILURE`;
export const UPDATE_HIDDEN_COLUMNNAMES = `${actionPrefix}UPDATE_HIDDEN_COLUMNNAMES`;
export const UPDATE_CURRENT_TABLE = `${actionPrefix}UPDATE_CURRENT_TABLE`;

const initialState = {
  main: {},
  compare: {},
  breakdown: {},
  meta: {},
  gettingData: false,
  dataFetched: false,
  errorMessage: false,
  selected: '',
  configObj: {},
  currentTable: '',
};

export const getData = configObj => dispatch => {
  dispatch({ type: GET_DATA_START, configObj });

  return api
    .post('/api/data', configObj)
    .then(response => dispatch({ type: GET_DATA_SUCCESS, payload: response.data }))
    .catch(error => dispatch({ type: GET_DATA_FAILURE, payload: error }));
};

export const getOverviewData = configObj => dispatch => {
  dispatch({ type: GET_DATA_START, configObj });

  return api
    .post('/api/data/overview', configObj)
    .then(response => dispatch({ type: GET_DATA_SUCCESS, payload: response.data }))
    .catch(error => dispatch({ type: GET_DATA_FAILURE, payload: error }));
};

const generateHiddenColumnNames = data => {
  if (_isEmpty(data)) {
    return {};
  }

  const hiddenColumnNames = {};

  data.meta.metrics.forEach(metric => {
    hiddenColumnNames[metric] = {
      regular: [`${metric}_compare`],
      total: [`${metric}_compare_total`],
    };
  });

  return hiddenColumnNames;
};

export const updateHiddenColumnNames = hiddenColumnNames => dispatch => {
  dispatch({ type: UPDATE_HIDDEN_COLUMNNAMES, hiddenColumnNames });
};

export const updateCurrentTable = currentTable => dispatch => {
  dispatch({ type: UPDATE_CURRENT_TABLE, currentTable });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DATA_START:
      return {
        ...state,
        gettingData: true,
        configObj: action.configObj,
      };
    case GET_DATA_SUCCESS:
      return {
        ...state,
        ...action.payload,
        ...generateHiddenColumnNames(action.payload),
        gettingData: false,
        dataFetched: true,
        errorMessage: false,
      };
    case GET_DATA_FAILURE:
      return {
        ...state,
        gettingData: false,
        errorMessage: 'Sorry, there is no data available for chosen parameters.',
      };
    case UPDATE_HIDDEN_COLUMNNAMES: {
      return {
        ...state,
        ...action.hiddenColumnNames,
      };
    }
    case UPDATE_CURRENT_TABLE: {
      return {
        ...state,
        currentTable: action.currentTable,
      };
    }
    default:
      return state;
  }
};

export default reducer;
