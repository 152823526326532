import api from '../../utils/api';

const actionPrefix = 'peribianreporting/auth/';

export const LOGIN_ADMIN = `${actionPrefix}LOGIN_ADMIN`;
export const VERIFY_USER_LOGGED_IN_START = `${actionPrefix}VERIFY_USER_LOGGED_IN_START`;
export const VERIFY_USER_LOGGED_IN_SUCCES = `${actionPrefix}VERIFY_USER_LOGGED_IN_SUCCES`;
export const VERIFY_USER_LOGGED_IN_FAILURE = `${actionPrefix}VERIFY_USER_LOGGED_IN_FAILURE`;

const initialState = {
  userLoggedIn: false,
  loader: true,
};

export const loginAdmin = () => {
  const endPoint = process.env.REACT_APP_API_ENDPOINT || '';
  const redirectUrlParam = process.env.REACT_APP_REDIRECT_URL
    ? `?redirectUrl=${process.env.REACT_APP_REDIRECT_URL}`
    : '';
  window.location = `${endPoint}/api/login${redirectUrlParam}`;
};

export const isUserLoggedin = () => dispatch => {
  dispatch({ type: VERIFY_USER_LOGGED_IN_START });

  return api
    .get('/api/login/authenticated')
    .then(response =>
      dispatch({ type: VERIFY_USER_LOGGED_IN_SUCCES, payload: response.data.isAuthenticated })
    )
    .catch(error => dispatch({ type: VERIFY_USER_LOGGED_IN_FAILURE, payload: error }));
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case VERIFY_USER_LOGGED_IN_START:
      return {
        ...state,
        loader: true,
      };
    case VERIFY_USER_LOGGED_IN_SUCCES:
      return {
        ...state,
        userLoggedIn: action.payload,
        loader: false,
      };
    case VERIFY_USER_LOGGED_IN_FAILURE:
      return {
        ...state,
        userLoggedIn: false,
        loader: false,
      };
    default:
      return state;
  }
};

export default auth;
