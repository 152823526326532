import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import CrashAndBurn from '../common/CrashAndBurn';
import { Progress, ErrorWrapper, ErrorDetails } from './index.styled';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, info) {
    this.setState({
      error: error,
      errorInfo: info,
    });
  }

  render() {
    const { errorInfo, error } = this.state;
    const { children } = this.props;

    if (errorInfo) {
      return (
        <Progress>
          <CrashAndBurn />
          <h5>Something went wrong.</h5>
          <Link to="/">Go to homepage</Link>
          <ErrorWrapper>
            <ErrorDetails>
              <code>
                <strong data-testid="error-message">{error && error.toString()}</strong>
                <br />
                {errorInfo.componentStack}
              </code>
            </ErrorDetails>
          </ErrorWrapper>
        </Progress>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
