export const ERRORS = {
  INVALID_CLIENT: 'error.invalid_client',
  INVALID_NETWORK: 'error.invalid_network',
  INVALID_PERIOD: 'error.invalid_period',
  INVALID_FROM_DATE: 'error.invalid_from_date',
  INVALID_TO_DATE: 'error.invalid_to_date',
  INVALID_COMPARE_FROM_DATE: 'error.invalid_compare_from_date',
  INVALID_COMPARE_TO_DATE: 'error.invalid_compare_to_date',
  FROM_GREATER_THAN_TO: 'error.from_greater_than_to',
  COMPARE_FROM_GREATER_THAN_COMPARE_TO: 'error.compare_from_greater_than_compare_to',
  FROM_DATE_NOT_START_OF_WEEK: 'error.from_date_not_start_of_week',
  TO_DATE_NOT_END_OF_WEEK: 'error.to_date_not_end_of_week',
  COMPARE_FROM_DATE_NOT_START_OF_WEEK: 'error.compare_from_date_not_start_of_week',
  COMPARE_TO_DATE_NOT_END_OF_WEEK: 'error.compare_to_date_not_end_of_week',
};

export const DATE_ERRORS = [
  ERRORS.INVALID_FROM_DATE,
  ERRORS.INVALID_TO_DATE,
  ERRORS.INVALID_COMPARE_FROM_DATE,
  ERRORS.INVALID_COMPARE_TO_DATE,
  ERRORS.FROM_GREATER_THAN_TO,
  ERRORS.COMPARE_FROM_GREATER_THAN_COMPARE_TO,
  ERRORS.FROM_DATE_NOT_START_OF_WEEK,
  ERRORS.TO_DATE_NOT_END_OF_WEEK,
  ERRORS.COMPARE_FROM_DATE_NOT_START_OF_WEEK,
  ERRORS.COMPARE_TO_DATE_NOT_END_OF_WEEK,
];

export const URL_UNRECOVERABLE_ERRORS = [ERRORS.INVALID_CLIENT];
