import React from 'react';
import Progress from 'react-loader-spinner';
import { styled } from 'reakit';

const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ProgressContainer = styled.div`
  background: #dd3333;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Loader = () => (
  <Wrapper>
    <ProgressContainer>
      <Progress type="Audio" color="white" height="80" width="80" />
    </ProgressContainer>
  </Wrapper>
);
export default Loader;
