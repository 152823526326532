import React from 'react';
import { styled, css, keyframes } from 'reakit';

const Container = styled.div`
  position: relative;
  width: 100%;
`;

const Wrapper = styled.div`
  background: red;
  width: 301px;
  height: 301px;
  border-radius: 50%;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  /* margin-left: 333px; */
  margin-top: 1px;
`;

const InnerWrapper = styled.div`
  position: absolute;
  bottom: 87px;
  background-color: white;
`;

const kf = keyframes`
  to {
    height: 0px;
  }
`;

const animation = css`
  animation: ${kf} 2s ease-out forwards;
  animation-delay: 1s;
`;

const Rect1 = styled.div`
  width: 26px;
  position: absolute;
  height: 37px;
  background-color: inherit;
  left: 53px;
  bottom: 0;
  ${animation}
`;

const Rect2 = styled.div`
  width: 26px;
  position: absolute;
  height: 62px;
  background-color: inherit;
  left: 92px;
  bottom: 0;
  ${animation}
`;

const Rect3 = styled.div`
  width: 26px;
  position: absolute;
  height: 112px;
  background-color: inherit;
  left: 131px;
  bottom: 0;
  ${animation}
`;

const Rect4 = styled.div`
  width: 26px;
  position: absolute;
  height: 87px;
  background-color: inherit;
  left: 170px;
  bottom: 0;
  ${animation}
`;

const r5wkf = keyframes`
  to {
    left: 139px;
  }
`;

const Rect5Wrapper = styled.div`
  position: absolute;
  width: 26px;
  left: 209px;
  bottom: 0;
  background-color: inherit;
  animation: ${r5wkf} 1s ease-in-out 4s;
  animation-fill-mode: forwards;
`;

const r5bkf = keyframes`
  to {
    border-radius: 50%;
  }
`;

const Rect5Bottom = styled.div`
  position: absolute;
  width: 26px;
  bottom: 0;
  height: 25px;
  background-color: inherit;
  animation: ${r5bkf} 1s ease-in-out 3s forwards;
`;

const Rect5Top = styled.div`
  position: absolute;
  width: 26px;
  height: 100px;
  bottom: 37px;
  background-color: inherit;
  ${animation}
`;

const qmkf = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const QuestionMark = styled.span`
  position: absolute;
  font-size: 200px;
  bottom: 71px;
  letter-spacing: 0;
  line-height: 0;
  left: -31px;
  color: white;
  opacity: 0;

  animation: ${qmkf} 1s ease-in-out forwards;
  animation-delay: 3s;
`;

const CrashAndBurn = () => {
  return (
    <Container>
      <Wrapper>
        <InnerWrapper>
          <Rect1 />
          <Rect2 />
          <Rect3 />
          <Rect4 />
          <Rect5Wrapper>
            <Rect5Bottom />
            <Rect5Top />
            <QuestionMark>?</QuestionMark>
          </Rect5Wrapper>
        </InnerWrapper>
      </Wrapper>
    </Container>
  );
};

export default CrashAndBurn;
