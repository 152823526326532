import axios from 'axios';
import Cookie from 'js-cookie';

if (process.env.NODE_ENV !== 'production') {
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('token');
  if (token) {
    Cookie.set('jwt_token', `JWT ${token}`);
  }
}

const api = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT || '',
  headers: {
    Authorization: Cookie.get('jwt_token'),
  },
});

export default api;
