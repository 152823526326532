import React from 'react';
import Icon from 'react-icons-kit';
import { googlePlus } from 'react-icons-kit/icomoon/googlePlus';
import { styled, Button } from 'reakit';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50vh;
`;

const Heading = styled.h2`
  margin-bottom: 20px;
  font-weight: 400;
`;

const SignInButton = styled(Button)`
  width: 160px;
  height: 60px;
  font-size: 20px;
  text-transform: capitalize;
  background: ${({ theme }) => theme.palette.googlePlus};
  color: white;
`;

const GooglePlus = styled(Icon)`
  margin-right: 15px;
`;

const SignIn = ({ onClick }) => {
  return (
    <Wrapper>
      <Heading>Peribian Reporting Tool</Heading>
      <SignInButton data-testid="sign-in-button" onClick={onClick}>
        <GooglePlus icon={googlePlus} size={30} />
        Sign in
      </SignInButton>
    </Wrapper>
  );
};

export default SignIn;
