import api from '../../utils/api';

const actionPrefix = 'peribianreporting/clients/';

export const GET_DATA_START = `${actionPrefix}GET_DATA_START`;
export const GET_DATA_SUCCESS = `${actionPrefix}GET_DATA_SUCCESS`;
export const GET_DATA_FAILURE = `${actionPrefix}GET_DATA_FAILURE`;
export const UPDATE_DATA = `${actionPrefix}UPDATE_DATA`;
export const UPDATE_NETWORK = `${actionPrefix}UPDATE_NETWORK`;
export const UPDATE_FILTERS = `${actionPrefix}UPDATE_FILTERS`;
export const RESET_FILTERS = `${actionPrefix}RESET_FILTERS`;

const initialState = {
  clients: [],
  gettingClients: false,
  errorMesssage: false,
  selectedClient: {},
  selectedFilters: [],
  network: '',
};

export const getClients = () => dispatch => {
  dispatch({ type: GET_DATA_START });

  return api
    .get('/api/data/clients')
    .then(response => dispatch({ type: GET_DATA_SUCCESS, payload: response.data }))
    .catch(error => dispatch({ type: GET_DATA_FAILURE, payload: error }));
};

export const updateSelectedClient = selectedClient => dispatch =>
  dispatch({
    type: UPDATE_DATA,
    selectedClient,
  });

export const updateNetwork = network => dispatch =>
  dispatch({
    type: UPDATE_NETWORK,
    network,
  });

export const updateFilterList = selectedFilters => dispatch =>
  dispatch({
    type: UPDATE_FILTERS,
    selectedFilters,
  });

export const resetFilters = () => dispatch =>
  dispatch({
    type: RESET_FILTERS,
  });

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DATA_START:
      return {
        ...state,
        gettingClients: true,
      };
    case GET_DATA_SUCCESS:
      return {
        ...state,
        clients: action.payload,
        gettingClients: false,
        errorMesssage: false,
      };
    case GET_DATA_FAILURE:
      return {
        ...state,
        gettingClients: false,
        errorMesssage: action.payload.message || 'Something went wrong',
      };
    case UPDATE_DATA:
      return {
        ...state,
        selectedClient: action.selectedClient,
      };
    case UPDATE_NETWORK:
      return {
        ...state,
        network: action.network,
      };
    case UPDATE_FILTERS:
      return {
        ...state,
        selectedFilters: action.selectedFilters,
      };
    case RESET_FILTERS:
      return {
        ...state,
        selectedFilters: [],
      };
    default:
      return state;
  }
};

export default reducer;
