import { createStore, applyMiddleware } from 'redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import rootReducer from './modules';
import { updateUrl } from './middleware/updateUrl';

const logger = createLogger();
const middleware = [thunk, logger, updateUrl];

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(...middleware)));

export default store;
