import api from '../../utils/api';

const actionPrefix = 'peribianreporting/export/';

export const GET_LINK_START = `${actionPrefix}GET_LINK_START`;
export const GET_LINK_SUCCESS = `${actionPrefix}GET_LINK_SUCCESS`;
export const GET_LINK_FAILURE = `${actionPrefix}GET_LINK_FAILURE`;

const initialState = {
  exportLink: false,
  gettingLink: false,
  errorMessage: false,
  openModal: false,
};

export const getExportLink = exportData => dispatch => {
  dispatch({ type: GET_LINK_START });

  return api
    .post('/api/data/export', exportData)
    .then(response => dispatch({ type: GET_LINK_SUCCESS, payload: response.data }))
    .catch(error => dispatch({ type: GET_LINK_FAILURE, payload: error }));
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LINK_START:
      return {
        ...state,
        openModal: false,
        gettingLink: true,
      };
    case GET_LINK_SUCCESS:
      return {
        ...state,
        exportLink: action.payload.exportLink,
        openModal: true,
        gettingLink: false,
        errorMessage: false,
      };
    case GET_LINK_FAILURE:
      return {
        ...state,
        gettingLink: false,
        errorMessage: 'Sorry, there is no export link available at the moment!',
      };
    default:
      return state;
  }
};

export default reducer;
