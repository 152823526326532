import {
  startOfISOWeek,
  endOfISOWeek,
  addWeeks,
  differenceInCalendarISOWeeks,
  differenceInCalendarMonths,
  startOfMonth,
  endOfMonth,
  addMonths,
  format,
} from 'date-fns';
import { pipe, append, reject, isNil, find, equals, identity, assoc } from 'ramda';
import { ERRORS, URL_UNRECOVERABLE_ERRORS, DATE_ERRORS } from '../constants/errors';
import { REQUEST_DATE_FORMAT, PERIODS } from '../constants/main';
import * as validators from './validators';

export const hasUnrecoverableError = (errors = []) =>
  !!errors.find(error => URL_UNRECOVERABLE_ERRORS.includes(error));

export const validateParams = ({
  client,
  network,
  period,
  from,
  to,
  compareFrom,
  compareTo,
  clients,
}) =>
  pipe(
    append(validators.validateClient(client, clients)),
    append(validators.validateNetwork(network, getClientsNetworks(client, clients))),
    append(validators.validatePeriod(period)),
    append(validators.validateDates({ from, to, compareFrom, compareTo })),
    reject(isNil)
  )([]);

const fixError = (error, errors, fixFn) => (find(equals(error))(errors) ? fixFn : identity);

const fixDateErrors = (errors, fixFn) => {
  const dateErrors = errors.filter(error => DATE_ERRORS.includes(error));
  return dateErrors.length > 0 ? fixFn : identity;
};

const defaultToOverviewNetwork = param => assoc('network', 'overview', param);
const defaultToWeeklyPeriod = param => assoc('period', PERIODS.week.value, param);

export const fixParams = (params, errors) => {
  return pipe(
    fixError(ERRORS.INVALID_NETWORK, errors, defaultToOverviewNetwork),
    fixError(ERRORS.INVALID_PERIOD, errors, defaultToWeeklyPeriod),
    fixDateErrors(errors, adjustDates)
  )({ ...params });
};

const getClientsNetworks = (selectedClient, clients) => {
  const client = clients.find(c => c.client_unique_id === selectedClient);
  return [...client.mainNetworks, ...client.otherNetworks, 'overview'];
};

const adjustDates = ({ from, to, compareFrom, compareTo, period, ...rest }) => {
  if (period === 'week') {
    const formattedFrom = startOfISOWeek(new Date(from));
    const formattedCompareFrom = startOfISOWeek(new Date(compareFrom));
    const formattedTo = endOfISOWeek(new Date(to));
    const rangeNumMain = differenceInCalendarISOWeeks(formattedTo, formattedFrom);

    return {
      ...rest,
      from: format(startOfISOWeek(formattedFrom), REQUEST_DATE_FORMAT),
      to: format(formattedTo, REQUEST_DATE_FORMAT),
      compareFrom: format(formattedCompareFrom, REQUEST_DATE_FORMAT),
      compareTo: format(
        endOfISOWeek(addWeeks(formattedCompareFrom, rangeNumMain)),
        REQUEST_DATE_FORMAT
      ),
      period,
      range: rangeNumMain,
    };
  }

  const formattedFrom = startOfMonth(new Date(from));
  const formattedCompareFrom = startOfMonth(new Date(compareFrom));
  const formattedTo = endOfMonth(new Date(to));
  const rangeNumMain = differenceInCalendarMonths(formattedTo, formattedFrom);

  return {
    ...rest,
    from: format(formattedFrom, REQUEST_DATE_FORMAT),
    to: format(formattedTo, REQUEST_DATE_FORMAT),
    compareFrom: format(formattedCompareFrom, REQUEST_DATE_FORMAT),
    compareTo: format(
      endOfMonth(addMonths(formattedCompareFrom, rangeNumMain)),
      REQUEST_DATE_FORMAT
    ),
    period,
    range: rangeNumMain,
  };
};
