import { isValid, startOfISOWeek, endOfISOWeek, isSameDay, compareAsc } from 'date-fns';
import { ERRORS } from '../constants/errors';
import { PERIODS } from '../constants/main';

export const validateClient = (client, clients) =>
  !!clients.find(c => client === c.client_unique_id) ? null : ERRORS.INVALID_CLIENT;

export const validateNetwork = (network, networks) =>
  networks.includes(network) ? null : ERRORS.INVALID_NETWORK;

export const validatePeriod = period => (PERIODS[period] ? null : ERRORS.INVALID_PERIOD);

export const validateDates = ({ from, to, compareFrom, compareTo }) => {
  if (!isValid(new Date(from))) {
    return ERRORS.INVALID_FROM_DATE;
  }

  if (!isValid(new Date(to))) {
    return ERRORS.INVALID_TO_DATE;
  }

  if (!isValid(new Date(compareFrom))) {
    return ERRORS.INVALID_COMPARE_FROM_DATE;
  }

  if (!isValid(new Date(compareTo))) {
    return ERRORS.INVALID_COMPARE_TO_DATE;
  }

  if (!isSameDay(new Date(from), startOfISOWeek(new Date(from)))) {
    return ERRORS.FROM_DATE_NOT_START_OF_WEEK;
  }

  if (!isSameDay(new Date(compareFrom), startOfISOWeek(new Date(compareFrom)))) {
    return ERRORS.COMPARE_FROM_DATE_NOT_START_OF_WEEK;
  }

  if (!isSameDay(new Date(to), endOfISOWeek(new Date(to)))) {
    return ERRORS.TO_DATE_NOT_END_OF_WEEK;
  }

  if (!isSameDay(new Date(compareTo), endOfISOWeek(new Date(compareTo)))) {
    return ERRORS.COMPARE_TO_DATE_NOT_END_OF_WEEK;
  }

  if (compareAsc(new Date(from), new Date(to)) !== -1) {
    return ERRORS.FROM_GREATER_THAN_TO;
  }

  if (compareAsc(new Date(compareFrom), new Date(compareTo)) !== -1) {
    return ERRORS.COMPARE_FROM_GREATER_THAN_COMPARE_TO;
  }

  return null;
};
