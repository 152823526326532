import { styled } from 'reakit';

export const Progress = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ErrorWrapper = styled.p`
  position: relative;
  left: -60px;
`;

export const ErrorDetails = styled.details`
  white-space: pre-wrap;
  position: absolute;
  width: 500px;
`;
