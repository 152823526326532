import theme from 'reakit-theme-default';

const defaultTheme = {
  ...theme,

  palette: {
    ...theme.palette,
    googlePlus: '#dd4b39',
    accentColor: '#ff324a',
    blue: ['#4a626d', '#5c717b', '#6e818a', '#809198', '#92a0a7'],
  },
};

export default defaultTheme;
