import { addWeeks, subWeeks, subYears, startOfISOWeek, endOfISOWeek, getISOWeek } from 'date-fns';

const actionPrefix = 'peribianreporting/pickers/';

export const PERIOD_CHANGE = `${actionPrefix}PERIOD_CHANGE`;
export const DATE_CHANGE = `${actionPrefix}DATE_CHANGE`;
export const DATE_CHANGE_COMPARE = `${actionPrefix}DATE_CHANGE_COMPARE`;
export const NUM_OF_WEEKS = `${actionPrefix}NUM_OF_WEEKS`;
export const UPDATE_PICKERS = `${actionPrefix}UPDATE_PICKERS`;

export const initialCompareWeekFrom = () => {
  let startingDate = startOfISOWeek(subWeeks(new Date(), 1));
  let compareFrom = startOfISOWeek(subYears(subWeeks(new Date(), 1), 1));

  if (getISOWeek(compareFrom) - getISOWeek(startingDate) < 0) {
    compareFrom = addWeeks(compareFrom, 1);
  } else if (getISOWeek(compareFrom) - getISOWeek(startingDate) > 0) {
    compareFrom = subWeeks(compareFrom, 1);
  }
  return compareFrom;
};

export const initialCompareWeekTo = () => {
  let endingDate = endOfISOWeek(subWeeks(new Date(), 1));
  let compareTo = endOfISOWeek(subYears(subWeeks(new Date(), 1), 1));
  if (getISOWeek(compareTo) - getISOWeek(endingDate) < 0) {
    compareTo = addWeeks(compareTo, 1);
  } else if (getISOWeek(compareTo) - getISOWeek(endingDate) > 0) {
    compareTo = subWeeks(compareTo, 1);
  }
  return compareTo;
};

const initialState = {
  period: 'week',
  startingDate: startOfISOWeek(subWeeks(new Date(), 1)),
  endingDate: endOfISOWeek(subWeeks(new Date(), 1)),
  compareFrom: initialCompareWeekFrom(),
  compareTo: initialCompareWeekTo(),
  rangeNumber: '',
};

export const updatePeriod = period => dispatch =>
  dispatch({
    type: PERIOD_CHANGE,
    period,
  });

export const updatePickers = (
  rangeNumber,
  startingDate,
  endingDate,
  compareFrom,
  compareTo
) => dispatch => {
  dispatch({
    type: UPDATE_PICKERS,
    rangeNumber,
    startingDate,
    endingDate,
    compareFrom,
    compareTo,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case PERIOD_CHANGE:
      return {
        ...state,
        period: action.period,
      };
    case DATE_CHANGE:
      return {
        ...state,
        startingDate: action.startingDate,
        endingDate: action.endingDate,
      };
    case DATE_CHANGE_COMPARE:
      return {
        ...state,
        compareFrom: action.compareFrom,
        compareTo: action.compareTo,
      };
    case UPDATE_PICKERS:
      return {
        ...state,
        ...action,
      };
    case NUM_OF_WEEKS:
      return {
        ...state,
        rangeNumber: action.rangeNumber,
      };
    default:
      return state;
  }
};

export default reducer;
