import React from 'react';
import { Link } from 'react-router-dom';
import { styled } from 'reakit';

const Progress = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const BadPageUrl = ({ url }) => (
  <Progress>
    <h3>404 - Not found {url ? { url } : ''}</h3>
    <Link to="/">Go to homepage</Link>
  </Progress>
);
export default BadPageUrl;
