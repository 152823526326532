import React from 'react';
import { Provider as ReakitProvider } from 'reakit';
import { MuiThemeProvider as MuiProvider, createMuiTheme } from '@material-ui/core/styles';
import defaultTheme from './defaultTheme';

const muiTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: {
      light: '#a6bfcc',
      main: '#778f9b',
      dark: '#4a626d',
      contrastText: '#000',
    },
    secondary: {
      light: '#e6ffff',
      main: '#b3e5fc',
      dark: '#82b3c9',
      contrastText: '#fff',
    },
  },
});

function ThemeProvider(props) {
  return (
    <ReakitProvider theme={defaultTheme}>
      <MuiProvider theme={muiTheme}>{props.children}</MuiProvider>
    </ReakitProvider>
  );
}

export default ThemeProvider;
