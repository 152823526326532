import { combineReducers } from 'redux';
import pickers from './pickers';
import tableData from './tableData';
import clients from './clients';
import auth from './auth';
import exports from './exports';
import urlParams from './urlParams';

export default combineReducers({
  pickers,
  tableData,
  clients,
  auth,
  exports,
  urlParams,
});
