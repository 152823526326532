import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import ThemeProvider from './services/theme/ThemeProvider';
import store from './redux/index';
import AppWrapper from './components/AppWrapper';
import PrivateRoute from './components/PrivateRoute';
import ErrorBoundary from './components/ErrorBoundary';
import BadUrlPage from './components/BadUrlPage';
import { getClients } from './redux/modules/clients';
import { isUserLoggedin } from './redux/modules/auth';
import Login from './components/Login';
import './styles.css';

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <ErrorBoundary>
        <ThemeProvider>
          <Switch>
            <Route path="/login" component={Login} onEnter={store.dispatch(isUserLoggedin())} />
            <PrivateRoute
              exact
              path="/:client?/:network?/:period?/:from?/:to?/:compareFrom?/:compareTo?"
              component={AppWrapper}
              onEnter={store.dispatch(getClients())}
            />
            <PrivateRoute component={BadUrlPage} />
          </Switch>
        </ThemeProvider>
      </ErrorBoundary>
    </Router>
  </Provider>,
  document.getElementById('root')
);
